import { useState, useEffect } from 'react';

export const useVisibilityError = (
  text: string,
  visibility: 'Public' | 'Friends' | 'Private',
  inheritedVisibility?: 'Public' | 'Friends' | 'Private'
) => {
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const hasMentions = /@\w+/.test(text);
    if (hasMentions) {
      if (inheritedVisibility === 'Private') {
        setError("This thread is private, can't tag friends");
      } else if (visibility === 'Private') {
        setError("Can't tag friends in private posts");
      } else {
        setError(null);
      }
    } else {
      setError(null);
    }
  }, [text, visibility, inheritedVisibility]);

  return error;
};
